document.addEventListener('DOMContentLoaded', function() {
  const menu = document.getElementById('menu')
  const button = document.getElementById('menu-button')
  
  button.addEventListener('click', navToggle)
  
  function navToggle() {
    menu.classList.toggle('flex')
    menu.classList.toggle('hidden')
    button.classList.toggle('open')
  }

  delete_cookie("_sn_a")
  delete_cookie("_sn_m")
  delete_cookie("_sn_n")
})

function delete_cookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
}
