function configureVimeo() {
  let vimeo = document.querySelector('#vimeo')

  if (vimeo == null) {
    return
  }
  
  let videoID = vimeo.dataset.videoId

  if (videoID == null) {
    return
  }

  let options = {
    id: videoID,
    width: vimeo.offsetWidth
  }

  let player = new Vimeo.Player('vimeo', options)
}

function stylePost() {
  document.querySelectorAll('.post-content p').forEach(function(element) {
    element.classList.add('text-coal', 'leading-loose', 'my-6', 'text-xl')
  })

  document.querySelectorAll('.post-content h2').forEach(function(element) {
    element.classList.add('text-coal', 'text-5xl', 'font-bold', 'mt-12', 'mb-2')
  })

  document.querySelectorAll('.post-content h3').forEach(function(element) {
    element.classList.add('text-coal', 'text-4xl', 'font-bold', 'mt-8', 'mb-2')
  })

  document.querySelectorAll('.post-content h4').forEach(function(element) {
    element.classList.add('text-coal', 'text-3xl', 'font-bold', 'mt-8', 'mb-2')
  })

  document.querySelectorAll('.post-content p code').forEach(function(element) {
    element.classList.add('text-pink', 'font-light', 'text-sm', 'border', 'border-gray-200', 'bg-gray-100', 'rounded-md', 'px-2', 'py-1')
  })

  document.querySelectorAll('.post-content img').forEach(function(element) {
    element.classList.add('border', 'border-gray-100', 'bg-gray-100')
  })

  document.querySelectorAll('.post-content blockquote').forEach(function(element) {
    element.classList.add('border-l-4', 'border-pink', 'px-8', 'py-1', 'italic')
  })
}

document.addEventListener('DOMContentLoaded', function() {
  configureVimeo()
})

stylePost()
